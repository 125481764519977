.MuiInputBase-root {
  @apply text-white;
  @apply rounded-lg;
  @apply p-2;
  @apply w-full;
  @apply text-sm;
}

.MuiTextField-root {
  @apply border-primary-500;
}

.MuiFormControl-root,
.MuiTextField-root {
  @apply border-primary-500 ;
}
/* class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd"
 */
