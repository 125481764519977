.otpInput {
  .MuiOtpInput-TextField:hover {
    border-color: red !important;

    border-radius: 20px;
  }
  .MuiInputBase-input:hover {
    border-radius: 20px;
    border-color: red !important;
  }
  .MuiOtpInput-Box:hover {
    border-radius: 20px;
    border-color: red !important;
  }

  .MuiTextField-root:hover {
  }
  .MuiInputBase-formControl:hover {
    border-color: red !important;
  }
  fieldset {
    border: 0.13rem solid #2b2b2b;
    border-radius: 20px;
  }

  fieldset:active {
    border-color: red !important;
    border-radius: 20px;
  }

  fieldset:hover {
    border-color: red !important;
    border-radius: 20px;
  }
}
.danger {
  fieldset {
    border: 0.13rem solid #ef4444 !important;
    border-radius: 20px;
  }
}

/* Animación de entrada (fade in) */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animación de salida (fade out) */
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

/* Clases de animación */
.animate-fadeIn {
  animation: fadeIn 0.3s forwards;
}

.animate-fadeIn-text {
  animation: fadeIn 0.3s ease-out;
}
.animate-fadeOut {
  animation: fadeOut 0.3s forwards;
}

.customHidden {
  display: none;
}
