dialog[open] {
  scale: 1;
  opacity: 1;
  padding: 1.6rem;

  transition: opacity 0.2s ease-out, scale 0.2s ease-out;
  @starting-style {
    scale: 0;
  }
}

dialog {
  transition: opacity 0.2s ease-out, scale 0.2s ease-out,
    display 0.2s ease-out allow-discrete;
  scale: 0;
  opacity: 0;
  margin: 0 auto;
}
