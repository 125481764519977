/* Animación de entrada (fade in) */
@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Animación de salida (fade out) */
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  
  /* Clases de animación */
  .animate-fadeIn {
    animation: fadeIn 0.6s forwards;
  }
  
  .animate-fadeOut {
    animation: fadeOut 0.6s forwards;
  }
  
  .customHidden {
    display: none;
  }
  